// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-above-beyond-garden-js": () => import("./../../../src/pages/above-&-beyond-garden.js" /* webpackChunkName: "component---src-pages-above-beyond-garden-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-griffin-painting-services-js": () => import("./../../../src/pages/griffin-painting-services.js" /* webpackChunkName: "component---src-pages-griffin-painting-services-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-itchy-sketchy-js": () => import("./../../../src/pages/itchy-&-sketchy.js" /* webpackChunkName: "component---src-pages-itchy-sketchy-js" */),
  "component---src-pages-kaiju-curry-house-js": () => import("./../../../src/pages/kaiju-curry-house.js" /* webpackChunkName: "component---src-pages-kaiju-curry-house-js" */),
  "component---src-pages-lemon-smart-business-card-js": () => import("./../../../src/pages/lemon-smart-business-card.js" /* webpackChunkName: "component---src-pages-lemon-smart-business-card-js" */),
  "component---src-pages-little-creek-farm-js": () => import("./../../../src/pages/little-creek-farm.js" /* webpackChunkName: "component---src-pages-little-creek-farm-js" */),
  "component---src-pages-louies-place-js": () => import("./../../../src/pages/louies-place.js" /* webpackChunkName: "component---src-pages-louies-place-js" */),
  "component---src-pages-salon-55-js": () => import("./../../../src/pages/salon-55.js" /* webpackChunkName: "component---src-pages-salon-55-js" */),
  "component---src-pages-under-construction-page-js": () => import("./../../../src/pages/under-construction-page.js" /* webpackChunkName: "component---src-pages-under-construction-page-js" */),
  "component---src-pages-working-home-page-js": () => import("./../../../src/pages/workingHomePage.js" /* webpackChunkName: "component---src-pages-working-home-page-js" */)
}

